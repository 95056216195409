import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import globalEnglish from './translations/en.json';
import globalFrench from './translations/fr.json';

const resources = {
	en: {
		translation: globalEnglish,
	},
	fr: {
		translation: globalFrench,
	},
}

const hostname = window.location.hostname;
const isDevOrStage = /dev|stage|localhost/.test(hostname);

if (!isDevOrStage) {
	i18next
	.use(I18nextBrowserLanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: (hostname === 'tdstudentcontest.td.com') ? 'en' : 'fr',
		resources: resources,
		detection: {
			order: [],
			lookupFromPathIndex: 0,
		},
		debug: false,
	});
} else {
	i18next
	.use(I18nextBrowserLanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'en',
		resources: resources,
		detection: {
			order: ['path', 'navigator', 'localStorage'],
			lookupFromPathIndex: 0,
		},
		debug: false,
	});
}
