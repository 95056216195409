import React from 'react';
import { useRoutes, Navigate, BrowserRouter } from "react-router-dom";
import { Main, LanguageWrapper } from "./components";
import { ThemeProvider, createTheme } from '@mui/material';
import i18next from 'i18next';
import './i18n';
import EnvironmentScripts from './components/Script/EnvironmentScripts';

// Create the MUI theme
const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

const AppContent = () => {
  const hostname = window.location.hostname;
  const isDevOrStage = /dev|stage|localhost/.test(hostname);

  const routes = useRoutes([
    !isDevOrStage ? {
      path: "*",
      element: <LanguageWrapper />,
      children: [
        { path: "", element: <Main /> },
      ]
    } : {
      path: "/",
      element: <Navigate to={`/${i18next.language.split('-')[0]}`} replace />
    },
    {
      path: "/:lang",
      element: <LanguageWrapper />,
      children: [
        { path: "", element: <Main /> },
      ]
    },
    {
      path: "*",
      element: <Navigate to={`/${i18next.language.split('-')[0]}`} replace />
    }
  ]);

  if (!isDevOrStage) {
    if (window.location.pathname.startsWith('/fr')) {
      window.location.href = "https://concoursetudianttd.td.com";
    }
    if (window.location.pathname.startsWith('/en')) {
      window.location.href = "https://tdstudentcontest.td.com";
    }
  }

  return routes;
};

function App() {
  return window.location.replace("https://www.td.com");
  /*return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <EnvironmentScripts />
          <AppContent />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );*/
}

export default App;
